import { lazy } from 'react';
import {
  UserOutlined,
  NodeIndexOutlined,
  BankOutlined,
  ReconciliationOutlined,
  HomeOutlined,
  TagsOutlined,
  FundProjectionScreenOutlined,
  DropboxOutlined,
  PhoneOutlined,
  AndroidOutlined,
  RadarChartOutlined,
  FileDoneOutlined,
  HddOutlined,
  VerticalAlignTopOutlined,
  LockOutlined,
  BlockOutlined,
  AlibabaOutlined,
  DingdingOutlined,
  AccountBookOutlined,
  FireOutlined,
  PicRightOutlined,
  ContactsOutlined
} from '@ant-design/icons';

const Doctor = lazy(() => import('./pages/Doctor'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const PrivacyPage = lazy(() => import('./pages/PrivacyPage'));
const Partner = lazy(() => import('./pages/Partner'));
const Specialty = lazy(() => import('./pages/Specialty'));
const DevicePage = lazy(() => import('./pages/DevicePage'));
const DoctorsPage = lazy(() => import('./pages/DoctorsPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const ProductsPage = lazy(() => import('./pages/ProductsPage'));
const Product = lazy(() => import('./pages/Product'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const Department = lazy(() => import('./pages/Department'));
const Appointment = lazy(() => import('./pages/Appointment'));
const Order = lazy(() => import('./pages/Order'));
const Device = lazy(() => import('./pages/Device'));
const AboutValue = lazy(() => import('./pages/AboutValue'));
const Brand = lazy(() => import('./pages/Brand'));
const Contact = lazy(() => import('./pages/Contact'));

const routes = [
  {
    key: Math.random(),
    icon: <FileDoneOutlined />,
    label: 'Pages',
    children: [
      {
        key: Math.random(),
        path: '/',
        component: HomePage,
        icon: <HomeOutlined />,
        label: 'Home Page'
      },
      {
        key: Math.random(),
        path: '/about-page',
        component: AboutPage,
        icon: <TagsOutlined />,
        label: 'About Page'
      },
      {
        key: Math.random(),
        path: '/device-page',
        component: DevicePage,
        icon: <AndroidOutlined />,
        label: 'Device Page'
      },
      {
        key: Math.random(),
        path: '/products-page',
        component: ProductsPage,
        icon: <DropboxOutlined />,
        label: 'Products Page'
      },
      {
        key: Math.random(),
        path: '/doctors-page',
        component: DoctorsPage,
        icon: <UserOutlined />,
        label: 'Doctors Page'
      },

      {
        key: Math.random(),
        path: '/contact-page',
        component: ContactPage,
        icon: <PhoneOutlined />,
        label: 'Contact Page'
      }
    ]
  },
  {
    key: Math.random(),
    icon: <HddOutlined />,
    label: 'Doctype',
    children: [
      {
        key: Math.random(),
        path: '/product',
        component: Product,
        icon: <DropboxOutlined />,
        label: 'Product'
      },
      {
        key: Math.random(),
        path: '/device',
        component: Device,
        icon: <FundProjectionScreenOutlined />,
        label: 'Device'
      },
      {
        key: Math.random(),
        path: '/doctor',
        component: Doctor,
        icon: <UserOutlined />,
        label: 'Doctor'
      },
      {
        key: Math.random(),
        path: '/department',
        component: Department,
        icon: <BankOutlined />,
        label: 'Department'
      }
    ]
  },
  {
    key: Math.random(),
    icon: <AccountBookOutlined />,
    label: 'Requests',
    children: [
      {
        key: Math.random(),
        path: '/order',
        component: Order,
        icon: <ReconciliationOutlined />,
        label: 'Order'
      },
      {
        key: Math.random(),
        path: '/appointment',
        component: Appointment,
        icon: <NodeIndexOutlined />,
        label: 'Appointment'
      },
      {
        key: Math.random(),
        path: '/contact',
        component: Contact,
        icon: <ContactsOutlined />,
        label: 'Contact'
      }
    ]
  },

  {
    key: Math.random(),
    icon: <FireOutlined />,
    label: 'Policy',
    children: [
      {
        key: Math.random(),
        path: '/privacy',
        component: PrivacyPage,
        icon: <LockOutlined />,
        label: 'Privacy'
      },

      {
        key: Math.random(),
        path: '/terms-and-conditions',
        component: TermsAndConditions,
        icon: <BlockOutlined />,
        label: 'Terms & Conditions Page'
      }
    ]
  },

  {
    key: Math.random(),
    icon: <PicRightOutlined />,
    label: 'Related Info',
    children: [
      {
        key: Math.random(),
        path: '/specialty',
        component: Specialty,
        icon: <RadarChartOutlined />,
        label: 'Specialty'
      },
      {
        key: Math.random(),
        path: '/about-values',
        component: AboutValue,
        icon: <VerticalAlignTopOutlined />,
        label: 'About Values'
      },
      {
        key: Math.random(),
        path: '/pharmacy-partner',
        component: Partner,
        icon: <AlibabaOutlined />,
        label: 'Pharmacy Partner'
      },
      {
        key: Math.random(),
        path: '/brand',
        component: Brand,
        icon: <DingdingOutlined />,
        label: 'Brand'
      }
    ]
  }
];
export default routes;
