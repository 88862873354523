import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfigProvider, Layout } from 'antd';

import SideBar from './utils/SideBar';
import CustomLayout from './utils/CustomLayout';
import Login from './pages/Login';
import './assets/styles/global.scss';
import { useAxiosGet } from './configs/axios';

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  let { pathname } = useLocation();
  pathname = pathname.replace('/', '');

  if (pathname == 'login') return <Login />;

  const { data: checker } = useAxiosGet(`/user/is-valid-token`, {
    autoRun: true
  });


  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#18C59F'
        }
      }}
    >
      <Layout>
        <SideBar collapsed={collapsed} />
        <CustomLayout setCollapsed={setCollapsed} collapsed={collapsed} />
      </Layout>
    </ConfigProvider>
  );
};

export default App;
