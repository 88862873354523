const API_URL = 'https://api.wellbeing-clinic.com';
// const API_URL = 'http://192.168.1.179:3100/';
export const localStorageName = 'name-website-auth';

export const OrderStatus = [
  {
    id: 1,
    label: 'Cancel',
    color: 'red'
  },
  {
    id: 2,
    label: 'Pending',
    color: 'orange'
  },
  {
    id: 3,
    label: 'Done',
    color: 'Green'
  }
];

export default API_URL;
